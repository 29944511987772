.Banner-Image {
  background: url("./blue-office2.jpg") no-repeat center center;
  background-size: cover;
  margin-top: -22px;
  display: flex;
  justify-content: center;
}
.Banner-Text {
  min-height: 200px;
  /* color: black; */
  color: white;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
/* .Banner-Text>h6{
    display: flex;
    justify-content: center;
    align-items: center;
} */
a {
  text-decoration: none;
  color: white;
}
h6 > span {
  color: goldenrod;
}
@media screen and (max-width: 768px) {
  .Banner-Text {
    flex-direction: column;
    justify-content: center;
  }
}
