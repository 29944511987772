/* #faq-div{
    width: 750px;
} */
/* .text-hover{
    transition: all 0.5s;
    width: 900px;
} */
.view-card>#blog-div{
    width: 100%;
}
/* .view-card>#faq-div{
    width: 90%;
} */
.text-hover:hover{
   background-color: #C8C8C8;
}

@media screen and (max-width: 1000px){
    #faq-div{
        width: 100%;
    }
    
    .text-hover{
        transition: all 0.5s;
        width: 100%;
    }
}