/* Navbar Styles */
.main-header {
  /* background-color: #011b32; */
  background-image: linear-gradient(180deg, #011b32 30%, #009ffd 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  position: sticky;
  left: 0px;
  top: -95px;
  z-index: 1000;
}
.main-header-top + hr {
  margin: 5px 0px;
}
.mobile {
  display: none;
}
.main-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px 0px 0px;
  margin: auto;
  width: 90%;
}

.main-header-bottom {
  /* background-color: #1d5699; */
  background-image: linear-gradient(360deg, #011b32 20%, #009ffd 100%);
  width: 90%;
  margin: auto;
  padding: 20px 0px;
  border-radius: 10px;
  /* border: 1px solid white; */
  margin-bottom: 5px;
}
.menu-icon {
  display: none;
}
/* Logo Styles */
#site-logo img {
  max-width: 150px;
}
hr {
  color: rgb(97, 108, 108);

  width: 100%;
}
/* Main Navigation */
.main-navigation ul.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
}

.main-navigation li.menu-item {
  margin-right: 20px;
  position: relative;
}

.main-navigation a {
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

.main-navigation a:hover {
  color: black;
  background-color: white;
  border-radius: 5px;
}

/* Dropdown Menu */
.main-navigation .sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  text-align: start;
  width: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0px 0px 5px 5px;
  border-top: 3px solid var(--orange);
  margin-top: 20px;
  z-index: 1000;
}

#site-navigation {
  position: sticky;
  top: 0;
  width: 100%;
}
.main-navigation .sub-menu li {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(147, 142, 142, 0.867);

  transition: all 0.5s ease-in-out;
}

.main-navigation .sub-menu a {
  /* color: rgb(48, 44, 44); */
  color: white;
  font-weight: 500;
  font-size: 16px;
  display: block;
}

.main-navigation .sub-menu a:hover {
  background-color: white;
  color: black;
  transition: all 0.5s ease-in-out;
}

/* Attractive Styles */
.main-header {
  text-align: center;
}
.main-header-top img {
  width: 150px;
}
.main-header a {
  font-size: 18px;
  font-weight: bold;
}

.topheader-text {
  font-size: 14px;
  color: #fff;
}

.topheader-text > p > strong > span:hover {
  color: gold;
  cursor: pointer;
}

/* Improve Dropdown Appearance */

/* Responsive Styles */
@media screen and (max-width: 1000px) {
  .main-header-top {
    flex-direction: row;
    text-align: center;
  }
  .main-header {
    top: -1px;
  }
  /* .container, .container-sm {
    max-width: 0px;
} */
  #site-navigation {
    display: none;
  }
  .mobile {
    width: 100%;
    padding: 0px;
  }
  .mobile li a {
    text-decoration: none;
    color: white;
    font-size: 15px;
  }
  .mobile li {
    list-style: none;
    border-bottom: 1px solid rgba(77, 75, 75, 0.815);
    margin: 0px;
    text-align: start;
    padding: 10px 0px;
  }
  #site-logo img {
    max-width: 120px;
  }

  .topheader-text {
    font-size: 12px;
    display: none;
  }

  .main-navigation ul.menu {
    flex-direction: column;
  }

  .main-navigation li.menu-item {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: start;
    padding: 10px 0px;
    width: 100%;
    border-bottom: 1px solid rgb(24, 142, 174);
    /* border: 1px solid white; */
  }

  .main-navigation .sub-menu {
    position: static;
    display: none;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
  }

  .main-navigation li.menu-item:hover .sub-menu {
    display: block;
  }

  .main-header-bottom {
    height: 0;
    overflow: hidden;
    width: 100%;
    padding: 0px;
    border: 0px;
  }

  .main-header-bottom.open {
    height: auto;
    animation: openAnimation 0.5s ease;
  }

  .main-header-bottom.open .close {
    opacity: 1;
    padding: 5px;
    animation: fadeIn 0.3s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      padding: 0px;
    }
    to {
      opacity: 1;
      padding: 5px;
    }
  }

  .main-header-bottom:not(.open) .close {
    opacity: 0;
    padding: 0px;
    animation: fadeOut 0.3s ease;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      padding: 5px;
    }
    to {
      opacity: 0;
      padding: 0px;
    }
  }

  /* Set the transition duration for the bars' transform in open state */
  .bar.open {
    transition: transform 0.3s ease;
  }

  @keyframes openAnimation {
    from {
      height: 0;
    }
    to {
      height: auto;
    }
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-right: 20px;
    z-index: 999;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: transform 0.3s ease;
  }

  .bar.open:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
  }

  .bar.open:nth-child(2) {
    opacity: 0;
  }

  .bar.open:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
}
