/* CSS for the "Specialised Services" section */
.career-box {
  padding: 0% 5%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 20px;
  padding-top: 25px;
}
.audit-section {
  padding: 5% 10%;
  background-color: #009efb;
}
/* #SpecialisedServices {
    margin-top: 20px;
  } */

.title-heading h5 {
  padding: 15px 40px;
  /* background: var(--orange); */
  background-image: linear-gradient(360deg, #011b32 20%, #009ffd 100%);
  border-radius: 10px 10px 0px 0px;

  color: #fff;
  margin-bottom: 0;
  text-align: center;
  border-bottom: 1px solid white;
}
.content-box {
  /* background-color: rgb(155, 158, 160); */
  /* background-color: #779ac2; */
  /* background-image: linear-gradient(
    #1d5699,
    #1d5699,
    #3467a3,
    #4a78ad,
   #4a78ad,
    #3467a3,
    #1d5699,
    #1d5699
  ); */
  background-image: linear-gradient(180deg, #011b32 30%, #009ffd 100%);
  margin-top: 0;
  padding: 5%;
  padding-top: 0;
  color: white;

  font-weight: bold;
  border-radius: 0px 0px 10px 10px;
}
.book_service_btn {
  /* background-color: #abd1fc; */
  background-image: linear-gradient(360deg, #011b32 20%, #009ffd 100%);
  padding: 2%;
  border-radius: 5px;
  font-weight: bold;
}
.content-box > .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  /* text-align: justify; */
}
.title-heading {
  margin-bottom: 6%;
}
.content-box img {
  margin: auto;
}

/* .content-box {
  margin-top: 20px;
} */

/* Responsive styles */
@media (max-width: 768px) {
  .content-box > .row {
    grid-template-columns: 1fr;
  }
  .career-box {
    padding: 0% 0%;
  }
  .career-box > div > div > p {
    padding: 0px 15px;
  }
  .audit-section {
    padding: 5% 1.5%;
  }
}
