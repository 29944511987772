.footer-section ul {
  margin: 0px;
  padding: 0px;
}
.single-cta {
  display: flex;
}
/* .single-cta > div:nth-child(1) {
  width: 150%;
} */

/* .single-cta:nth-child(1) {
  flex-grow: 5;
}
.single-cta:nth-child(2),
.single-cta:nth-child(3) {
  flex-grow: 1;
} */

.footer-section {
  /* background: #011b32; */
  background-image: linear-gradient(180deg, #011b32 20%, #009ffd 100%);
  border-radius: 20px 20px 0px 0px;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #ffffff82;
}
/* .footer-cta > row {
  display: flex;
} */
.single-cta i {
  color: var(--orange);
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: white;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #fff;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
/* .facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: rgb(255, 72, 0);
} */
.plane-icon {
  color: white;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--orange);
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: gold;
}
.footer-widget ul li a {
  color: #fff;
  text-transform: capitalize;
  text-decoration: unset;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #fff;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: var(--orange);
  padding: 14px 20px;
  border: 1px solid white;
  top: 0;
  height: 100%;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
/* .copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: var(--orange);
} */
.footer-social-icon {
  display: flex;
  justify-content: start;
  align-items: center;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: var(--orange);
}
.footer-menu li a {
  font-size: 14px;
  color: #fff;
}
/* @media screen and (max-width: 768px) {
  .footer-content .row * {
    font-weight: lighter;
  }
} */
