.chakra-card.css-t3blsc {
  box-shadow: rgba(0, 128, 0, 0.4) 0px 4px 8px -2px;
}
.chakra-button.css-1tduvxh {
  background-image: linear-gradient(315deg, #f5d020 0%, #f53803 74%);
}
.chakra-button.css-1tduvxh:hover {
  background-color: rgb(104, 29, 29);
}
.chakra-button.css-1goj59o {
  background-color: brown;
}

.services .slick-track a {
  display: flex;
  justify-content: center;
}
