.Adminnavbar input[type="checkbox"],
.Adminnavbar .hamburger-lines {
  display: none;
}
.logOut{
  display: none;
}
.navbar-container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}
.tox-statusbar__branding{
  display: none;
}

.Adminnavbar {
  height: 60px;
  /* background-color: rgb(236, 232, 232); */
  position: sticky;
  top: 0;
  width: 100%-2px;
  z-index: 999;
  /* background-color: brown; */
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}

.menu-items {
  order: 2;
  display: flex;
  margin: auto;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  font-size: 1.3rem;
}

.Adminnavbar a {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.Adminnavbar a:hover {
  color: #ffffff;
}

@media (max-width: 1000px) {
  /* .Adminnavbar{
        opacity: 0.95;
    } */

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }
  .logOut{
    display: block;
  }
  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #333;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .Adminnavbar .menu-items {
    margin-top: -10px;
    padding-top: 100px;
    background-color: #2a2a72;
    background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
    height: 100vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -60px;
    padding-left: 40px;
    padding-right: 60px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
  }

  .Adminnavbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .Adminnavbar  .logo {
    position: absolute;
    right: 15px;
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
}
.loginDiv .Form{
  width: 50%;
  background-color: white;
  padding: 40px;
  border-radius: 30px;
  margin: 20px auto;
}
@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    z-index: -1;
    margin: auto;
  }
  .loginDiv .Form{
    width: 95%;
    margin: 50px auto;
  }
}
