.about-us-section {
  padding: 5% 10%;
  background-color: #009efb;
}
.about-us-container {
  background-color: white;
  padding: 0% 5% 5%;
  border-radius: 20px;
  text-align: justify;
  /* text-align: center; */
}
.team-section * {
  width: fit-content;
  color: rgb(255, 255, 255);
}
.team-section {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  border-radius: 20px;
  padding: 50px 15px;
  /* background-color: #02223d; */
  background-image: linear-gradient(180deg, #011b32 20%, #009ffd 100%);
}
.share a {
  margin-right: 10px;
  font-size: 20px;
}
.about-img {
  float: right;
  margin-left: 26px;
  height: auto;
  max-width: 40%;
  border-radius: 10px;
  max-height: 300px;
  margin-top: -85px;
  /* border: 1px solid white; */
}
@media screen and (max-width: 630px) {
  .about-us-container {
    padding: 0px 0px;
  }
  .about-us-content > div > p {
    padding: 0px 15px;
  }
  .about-img {
    margin-top: -10px;
  }
  .about-us-section {
    padding: 5% 1.5%;
  }
  .team-member * {
    font-size: 14px;
  }
}
