/* Viwe.css */
.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.pagination li {
  cursor: pointer;
  margin: 0 5px;
  padding: 5px 15px;
  border: 1px solid #ccc;
  background-color: white;
  transition: all 0.5s;
}

.pagination li:hover {
  background-color: black;
  color: #ffff;
  transition: all 0.5s;
}

.pagination li.active {
  background-color: var(--orange);
  color: white;
  font-weight: bold;
}
.title-text {
  margin-top: 20px;
}

.view-card {
  /* width: 300px; */
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  justify-content: center;
}
.view-card > div:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.5s;
}
.view-card > div > div > img {
  width: 100%;
}

.view-card > div > div:hover img + .youtube {
  opacity: 1;
  cursor: pointer;
  transition: all 0.5s;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.youtube {
  font-size: 50px;
  color: red;
  position: absolute;
  opacity: 0;
  transition: all 0.5s;
}
.View-main {
  display: flex;
  background-color: #009efb;
  justify-content: center;
}
.view {
  display: flex;
  width: 90%;
  gap: 20px;
  padding: 30px;
  justify-content: space-around;
}
.view-card {
  min-height: 200px;
}
.view-card > div {
  width: 350px;
  padding: 30px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.view-filter > div > h3 {
  padding: 5px 30px;
}
.view-filter > div > div {
  margin-top: 23px;
  margin-top: 20px;
  margin-bottom: -5px;
}
.view-filter > div {
  padding: 30px 0px 0px 0px;
  position: relative;
}
.view-filter > div > div > p {
  padding: 10px 30px;
}
.view-filter > div > div > p:not(:first-child) {
  margin-top: -16px;
}
.view-filter > div > div > p {
  border-top: 1px solid rgba(125, 121, 121, 0.844);
  transition: all 0.5s;
}
.view-filter > div > div > p:hover {
  background-color: var(--orange);
  color: white;
  transition: all 0.5s;
  cursor: pointer;
}

.view-filter > div {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}
@media screen and (max-width: 1000px) {
  .view {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    justify-content: center;
  }
  .view-card {
    /* width: 300px; */
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .view-card > div {
    width: 100%;
  }
}
