.testimonial-1 {
  padding: 10px;
  height: 100%;
}
.testimonial {
  height: 100%;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: white;
}
.testimonial-main {
  padding: 0px 3.125rem;
}
.testimonial-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.testimonial-name {
  font-size: 18px;
  margin-bottom: 5px;
}

.testimonial-message {
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.clinet-reviews ul {
  list-style-type: none;
}
.clinet-reviews a {
  text-decoration: none;
  color: #1d5699;
  margin: 10px;
  font-size: 25px;
}
.clinet-reviews h1,
.clinet-reviews h2,
.clinet-reviews h3,
.clinet-reviews h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
.clinet-reviews h1 {
  font-size: 3rem;
}
.clinet-reviews h2 {
  font-size: 2rem;
}
.clinet-reviews h3 {
  font-size: 1.25rem;
}
.clinet-reviews h4 {
  font-size: 0.875rem;
}
.clinet-reviews p {
  margin-bottom: 1.25rem;
  /* color: #4e4a67; */
}
@media screen and (min-width: 800px) {
  .clinet-reviews h1 {
    font-size: 4rem;
  }
  .clinet-reviews h2 {
    font-size: 2.5rem;
  }
  .clinet-reviews h3 {
    font-size: 1.75rem;
  }
  .clinet-reviews h4 {
    font-size: 1rem;
  }
  .clinet-reviews h1,
  .clinet-reviews h2,
  .clinet-reviews h3,
  .clinet-reviews h4 {
    line-height: 1;
  }
}
/*  global classes */

/* section */
.clinet-reviews .section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .clinet-reviews .section {
    width: 95vw;
  }
}

/*
=============== 
Reviews
===============
*/
.clinet-reviews {
  min-height: 100vh;
  display: grid;
  place-items: center;
  margin-bottom: 20px;
}
.clinet-reviews .title {
  text-align: center;
  margin-bottom: 4rem;
}
/* .clinet-reviews .underline {
  height: 0.25rem;
  width: 5rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
} */
.clinet-reviews .container {
  /* width: 80vw;
  max-width: var(--fixed-width); */
}
.review {
  background: var(--clr-white);
  padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  text-align: center;
  box-shadow: rgba(0, 0, 128, 0.5) 0px 4px 10px 0px;
}
.review:hover {
  /* background: var(--clr-white); */
  background-image: linear-gradient(180deg, #011b32 20%, #009ffd 100%);
  color: white;
  /* padding: 1.5rem 2rem;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  text-align: center;
  box-shadow: rgba(0, 0, 128, 0.5) 0px 4px 10px 0px; */
}

/* .clinet-reviews .review:hover {
  box-shadow: var(--dark-shadow);
} */
.clinet-reviews .img-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.clinet-reviews .person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}
.clinet-reviews .quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background-image: linear-gradient(
    #1d5699,
    #1d5699,
    #3467a3,
    #4a78ad,
    #6189b8,
    #779ac2,
    #4a78ad,
    #3467a3,
    #1d5699,
    #1d5699
  );
  color: white;
}
.clinet-reviews .img-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    #1d5699,
    #1d5699,
    #3467a3,
    #4a78ad,
    #6189b8,
    #779ac2,
    #4a78ad,
    #3467a3,
    #1d5699,
    #1d5699
  );
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}
.clinet-reviews .author {
  margin-bottom: 0.25rem;
}
.clinet-reviews .job {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: var(--clr-primary-5);
  font-size: 0.85rem;
}
.clinet-reviews .info {
  margin-bottom: 0.75rem;
  text-align: justify;
}

.clinet-reviews .icon {
  font-size: 4rem;
}
.team-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media screen and (max-width: 770px) {
  .team-container {
    grid-template-columns: 1fr;
  }
}
