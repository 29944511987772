.card-div {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 300px;
  padding: max(15px, 2%);
  position: relative;
  color: #4e4a67;
  /* font-weight: bold; */
}
.card-div * {
  text-align: justify;
}
.card-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: var(--orange); */
  background-image: linear-gradient(180deg, #011b32 20%, #009ffd 100%);
  border-radius: 10px;
  width: 300px;
  display: flex;
  height: 100%;
  /* transform: translateX(-100%); */
  transition: all 1s linear;
  z-index: -1;
  transform-origin: 0 0;
  transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
  transform: scaleY(0);
}
.card-div:hover:before {
  transform: scaleY(1);
}
.card-div:hover {
  color: white;

  cursor: pointer;
}
.card-div > h3 {
  font-size: 20px;
}
.card-main {
  display: flex;
  width: min(97%, 1300px);
  margin: auto;
  gap: max(2%, 20px);

  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #009efb; */
}
