* {
  scroll-behavior: smooth;
}
.Box-outer-shadow {
  box-shadow: rgba(31, 31, 212, 0.651) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  width: 100%;
  border-radius: 6px;
}
.carousel-item {
  z-index: -99;
}
.Box-outer-shadow * {
  text-align: center;
}
.content-main-div > img {
  width: 100%;
}
.concept-card {
  width: 100%;
  padding: 0px 20px;
  gap: 40px;
}
.Flex {
  display: flex;
  justify-content: center;
  align-items: start;
}
.concept-icon {
  border-bottom: 4px solid var(--orange);
  padding: 20px 10px;
  width: fit-content;
  font-size: 20px;
  transition: 1.5s;
  border-radius: 50%;
  margin-top: 20px;
  background-color: white;
}
.column-inner {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.wpb_wrapper {
  margin: auto;
}
.concept-card > div:hover .concept-icon {
  cursor: pointer;
  rotate: 360deg;
  color: rgb(151, 147, 147);
  border: 4px solid var(--orange);
  /* background-color: var(--text-gold); */
  transition: 1.5s;
}
.concept-card > div:hover {
  scale: 1.1;
  transition: 1.2s;
}
.arrow {
  margin-top: 3px;
}
.react-multiple-carousel__arrow::before {
  color: var(--orange);
}
#concept-card a {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.concept-card > div {
  width: 28%;
  position: relative;

  margin-top: -30px;
  /* margin-bottom: 20px; */
  background-color: white;
  /* padding: 20px; */
}
.concept-card > div > div > p {
  font-size: 1.1vw;
}

.concept-card > div > div:nth-child(2) {
  padding: 15px;
}
.concept-card > div > div:last-child > a:hover {
  color: var(--orange);
}
.concept-card > div > div:last-child {
  padding: 13px;
  border-radius: 0px 0px 10px 10px;
}
.concept-card > div > div {
  margin: 0px;
  width: 100%;
}
@media only screen and (max-width: 530px) {
  .content-main-div {
    margin-top: 60px;
  }
}
.heading_content {
  padding: 0px;
  margin-top: 130px;
  border-radius: 10px;
}
.carousel-control-next,
.carousel-control-prev {
  top: 50%;
  height: fit-content;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 1.5rem !important;
  }
  .topheader-text {
    display: none;
  }

  .text {
    font-size: 0.9rem !important;
  }
  .heading_container {
    width: 80% !important;
  }
  .about_btn {
    padding: 0.5rem 1rem !important;
    transition: 0.5s !important;
  }
  .image_wrapper {
    margin-top: 60px;
  }
  .top_clousal_img {
    display: none;
  }

  .heading_container {
    top: 50% !important;
  }
}
@media only screen and (max-width: 350px) {
  .text {
    display: none;
  }
}
@media only screen and (max-width: 900px) {
  #concept-card {
    flex-direction: column;
    gap: 50px;
    margin-top: -80px;
    justify-content: center;
    align-items: center;
  }
  .carousel-indicators {
    display: none;
  }
  .concept-card {
    padding: 0px 0px;
  }
  #concept-card > div {
    width: 95%;
    margin-top: 80px;
  }
  #concept-card h3 {
    font-size: 27px;
  }
  #concept-card p {
    font-size: 18px;
  }
  #concept-card a {
    font-size: 20px;
  }
}
.main_container {
  position: relative;
  height: 85vh;
  width: 100%;
  z-index: -1;
}

.image_wrapper {
  position: relative;
  width: 100%;
}
.d-block {
  filter: brightness(50%);
}
.image_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('closure-image.jpg'); Replace 'closure-image.jpg' with the path to your closure image */
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
}

.heading_container {
  position: absolute;
  top: 60%;
  left: 10%;
  transform: translateY(-50%);
  z-index: 10;
  width: 50%;
}

.heading_content {
  /* padding: 20px; */
  /* background-color: rgba(255, 255, 255, 0.9); */
  border-radius: 10px;
}
.heading_content > * {
  color: white;
  text-align: left;
}
.heading {
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.726);
  color: white;
}

.text {
  font-size: 1.2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
  color: white;
}
.about_btn {
  display: flex;
  background-color: var(--orange);
  align-items: center;
  gap: 0.63rem;
  border: 1px solid white;
  padding: 0.625rem 1.875rem;
  transition: 0.5s;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.about_btn:hover {
  background: linear-gradient(
    to right,
    #ff99009f 10%,
    rgba(218, 216, 216, 0.753) 90%
  );
}

/* Add any additional styles you need */
