.about-div {
  display: flex;
  /* gap: 20px; */
  width: 90%;
  padding: 30px;
  margin-top: 100px;
}
.about-main {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-div > div {
  width: 50%;
}
.about-img > img {
  width: 100%;
  height: 100%;
}
.about-text {
  background-color: var(--orange);
  padding: 30px;
  vertical-align: middle;
  color: white;
}
.about-text {
  display: flex;
  flex-direction: column; /* Arrange content vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: justify;
  /* height: 100vh; Set the desired height for vertical centering */
}

/* Additional styles for the 'section-title' and 'p' elements (optional) */
.section-title {
  font-size: 24px;
  font-weight: bold;
  /* Add any other styles you want for the title */
}

.about-text p {
  font-size: 18px;
  /* Add any other styles you want for the paragraph text */
}
@media (max-width: 1100px) {
  .about-div {
    flex-direction: column;
  }
  .about-div > div {
    width: 100%;
  }
}
