.contact-form {
  max-width: 100%;
  margin: 0 auto;
  padding: 5%;
  border: 1px solid #ccc;
  border-radius: 20px;
  /* margin-top: 5% 10%; */
}

.form-group {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}
.contact-div {
  padding: 2% 5%;
  width: 80%;
  margin-top: 5%;
  background-color: white;
  margin-bottom: 80px;
  border-radius: 20px;
}
.form-group input,
.form-group textarea {
  flex: 1;
  padding: 15px;
  font-size: 18px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.social_link a {
  color: #1d5699;
  font-size: 25px;
}

.form-group input[type="submit"] {
  background-color: #007bff;

  color: #fff;
  cursor: pointer;
}

.form-group input[type="submit"]:hover {
  background-color: #0056b3;
}
.submit {
  padding: 1% 2%;
  border: 0;
  /* background-color: var(--orange); */
  background-image: linear-gradient(360deg, #011b32 20%, #009ffd 100%);
  color: white;
  font-weight: bold;
  border-radius: 5px;
}
.submit:hover {
}
.form-group input[type="submit"]:focus {
  outline: none;
}
/* .youtube-icon {
  color: var(--orange);
}
.linkdin-icon {
  color: var(--orange);
} */
.form-group textarea {
  resize: vertical;
}
/* .email-phone {
  display: flex;
  gap: 50px;
} */
/* .email {
  color: var(--orange);
} */
.social_link {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }
  .social_link {
    justify-content: start;
  }
  .contact-div {
    width: 97%;
    /* margin-top: 0px; */
    /* padding: 20px; */
  }
  .form-group input,
  .form-group textarea {
    width: 100%;
  }
  .email-phone {
    flex-direction: column;
    gap: 0px;
  }
  .form-group input[type="submit"] {
    width: auto;
    margin-top: 10px;
  }
  /* .contact-div div h1,
  .contact-div div div h4 {
    font: 12px;
  } */
}
