#root .carousel-control-next,
#root .carousel-control-prev {
  width: fit-content;
}
.heading_container_slide {
  width: 80%;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  color: white;
}

.top_clousal_img{
  width: 80%;
  display: flex;
  justify-content: end;
}
.top_clousal_img img{
  width: 80%;
}
.main_container{
  background-image: url(./home_backgroundv2.jpg);
}
@media (max-width: 768px) {
  .main_container{
    background-image: url(./home_backgroundv3.jpg);
  }
   .heading_container_slide{
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    margin: auto;
   }
   #carouselExampleControls{
    height: 500px;
  }
}
#carouselExampleControls{
  z-index: -2;
  
}