:root {
  --skin-color: #dcdadaef;
  --skin-color1: #ffffffd6;
  --skin-color2: #1fc586;
  --skin-color3: #2eb1ed;
  --skin-color4: #cc3a3b;
  --bg-black-900: #000;
  --bg-black-100: #ddd;
  --bg-black-50: #eff0f4;
  /* --orange: #ff5e14; */
  /* --orange: #1486ff8e; */
  --orange: #1d5699;
  /* --orange: #e26310;
  --orange: #028391;
  --orange: #06394d; */
  --text-black-900: #000;
  --text-black-700: #555;
  --text-black-600: #666;
  --text-black-300: #bbb;
  --outer-shadow: 3px 3px 3px #d0d0d0, -3px -3px 3px #f8f8f8;
  --outer-shadow-0: 0 0 0 #d0d0d0, 0 0 0 #f8f8f8;
  --inner-shadow: inset 3px 3px 3px #d0d0d0, inset -3px -3px 3px #f8f8f8;
  --inner-shadow-gold: inset 3px 3px 3px #beb35b, inset -3px -3px 3px #c2b44d;
}
